@font-face {
    font-family: 'Nunito';
    src: local('Nunito'),
        url('./assets/fonts/Nunito/Nunito-VariableFont_wght.ttf') format('truetype');
}

@font-face {
    font-family: 'Nunito';
    src: local('Nunito'),
        url('./assets/fonts/Nunito/Nunito-Italic-VariableFont_wght.ttf') format('truetype');
    font-style: italic;
}

@font-face {
    font-family: 'Nunito';
    src: local('Nunito'),
        url('./assets/fonts/Nunito/Nunito-VariableFont_wght.ttf') format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'Nunito-black';
    src: local('Nunito'),
        url('./assets/fonts/Nunito/static/Nunito-Black.ttf') format('truetype');
    font-weight: 900;
}

@font-face {
    font-family: 'Nunito-medium';
    src: local('Nunito'),
        url('./assets/fonts/Nunito/static/Nunito-Medium.ttf') format('truetype');
}
