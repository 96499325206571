@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?95488260');
  src: url('../font/fontello.eot?95488260#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?95488260') format('woff2'),
       url('../font/fontello.woff?95488260') format('woff'),
       url('../font/fontello.ttf?95488260') format('truetype'),
       url('../font/fontello.svg?95488260#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?95488260#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-arrows-apposite-1:before { content: '\e800'; } /* '' */
.icon-search:before { content: '\e81f'; } /* '' */
.icon-arrow-doble-down-money:before { content: '\e820'; } /* '' */
.icon-arrow-down-circle:before { content: '\e821'; } /* '' */
.icon-arrows-apposite:before { content: '\e822'; } /* '' */
.icon-arrow-up-circle:before { content: '\e823'; } /* '' */
.icon-bar-code:before { content: '\e824'; } /* '' */
.icon-bars:before { content: '\e825'; } /* '' */
.icon-bell:before { content: '\e826'; } /* '' */
.icon-cart:before { content: '\e827'; } /* '' */
.icon-chat-money:before { content: '\e828'; } /* '' */
.icon-check:before { content: '\e829'; } /* '' */
.icon-chevron-down:before { content: '\e82a'; } /* '' */
.icon-chevron-left:before { content: '\e82b'; } /* '' */
.icon-chevron-right:before { content: '\e82c'; } /* '' */
.icon-chevron-up:before { content: '\e82d'; } /* '' */
.icon-circle-money:before { content: '\e82e'; } /* '' */
.icon-coins:before { content: '\e82f'; } /* '' */
.icon-credit-card-coin:before { content: '\e830'; } /* '' */
.icon-credit-card-fulled:before { content: '\e831'; } /* '' */
.icon-exit:before { content: '\e832'; } /* '' */
.icon-eye-closed:before { content: '\e833'; } /* '' */
.icon-eye-open:before { content: '\e834'; } /* '' */
.icon-hand-money:before { content: '\e835'; } /* '' */
.icon-gear:before { content: '\e836'; } /* '' */
.icon-head-phone:before { content: '\e837'; } /* '' */
.icon-home:before { content: '\e838'; } /* '' */
.icon-lock-open:before { content: '\e839'; } /* '' */
.icon-mail:before { content: '\e83a'; } /* '' */
.icon-people:before { content: '\e83b'; } /* '' */
.icon-peoples:before { content: '\e83c'; } /* '' */
.icon-people-support:before { content: '\e83d'; } /* '' */
.icon-recipe:before { content: '\e83e'; } /* '' */
.icon-search-1:before { content: '\e83f'; } /* '' */
.icon-tag-money:before { content: '\e840'; } /* '' */
.icon-menu:before { content: '\f0c9'; } /* '' */
